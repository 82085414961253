import { Image } from '@deity/falcon-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from '../../../ui/Button/Button';
import { Labels } from '../../Labels';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { LazyLoad } from '../../../helpers/LazyLoad/LazyLoad';
import { LinkWrap } from '../../../helpers/LinkWrap/LinkWrap';
import styles from './ProductAdvertisement.module.scss';

export const isUrlExternal = url => url.indexOf('http') !== -1 || url.indexOf('www') !== -1;

export const ProductAdvertisement = withStoreConfiguration(
  ({
    border,
    background,
    subtitle,
    backgroundTop,
    backgroundBottom,
    textVerticalAlign,
    textAlign,
    textColor,
    text,
    showButton,
    buttonText,
    buttonLink,
    header,
    headerColor,
    hideHeader,
    showHeaderUnderline,
    gradientTopColor,
    gradientBottomColor,
    doubleSized,
    discount,
    currentIndex,
    storeConfiguration,
    isLink
  }) => {
    const additionalClasses = [];
    const isAdvert = text.length === 1;
    const magentoUrl = storeConfiguration?.web?.unsecure?.base_media_url;
    const isLinkUrlExternal = isUrlExternal(buttonLink);
    const linkUrl = !isLinkUrlExternal && buttonLink.indexOf('/') !== 0 ? `/${buttonLink}` : buttonLink;

    const button = showButton ? (
      <Button
        asLink={linkUrl && !isLinkUrlExternal && !isLink}
        asStandardLink={linkUrl && isLinkUrlExternal && !isLink}
        path={linkUrl}
        size={doubleSized || isAdvert ? 'medium' : 'small'}
      >
        {buttonText}
      </Button>
    ) : null;
    const backgroundGradient =
      gradientTopColor && gradientBottomColor ? `linear-gradient(${gradientTopColor}, ${gradientBottomColor})` : null;

    if (currentIndex % 3 === 1) {
      additionalClasses.push(styles.ProductAdvertisementCardDesktop);
    }

    if (currentIndex % 4 === 1) {
      additionalClasses.push(styles.ProductAdvertisementCardTablet);
      additionalClasses.push(styles.ProductAdvertisementCardTabletFirst);
    }

    if (currentIndex % 4 === 2) {
      additionalClasses.push(styles.ProductAdvertisementCardTablet);
      additionalClasses.push(styles.ProductAdvertisementCardTabletSecond);
    }

    if (isAdvert) {
      additionalClasses.push(styles.ProductAdvertisementAdvert);
    }

    if (border) {
      additionalClasses.push(styles.ProductAdvertisementBorder);
    }

    if (doubleSized) {
      additionalClasses.push(styles.ProductAdvertisementDouble);
      if (currentIndex % 3 === 0) {
        additionalClasses.push(styles.ProductAdvertisementDoubleLeft);
      }
      if (currentIndex % 3 === 1) {
        additionalClasses.push(styles.ProductAdvertisementDoubleRight);
      }
    }

    if (textVerticalAlign === 'top') {
      additionalClasses.push(styles.ProductAdvertisementContentTop);
    } else if (textVerticalAlign === 'center') {
      additionalClasses.push(styles.ProductAdvertisementContentMiddle);
    } else {
      additionalClasses.push(styles.ProductAdvertisementContentBottom);
    }

    if (textAlign === 'center') {
      additionalClasses.push(styles.ProductAdvertisementContentCenter);
    } else if (textAlign === 'left') {
      additionalClasses.push(styles.ProductAdvertisementContentLeft);
    } else {
      additionalClasses.push(styles.ProductAdvertisementContentRight);
    }

    return (
      <div
        className={[styles.ProductAdvertisement, 'preventSelection', ...additionalClasses].join(' ')}
        style={{ color: textColor, background: backgroundGradient }}
      >
        <LinkWrap wrap={isLink} standardHtmlLink={isLinkUrlExternal} link={linkUrl}>
          {isAdvert && discount ? (
            <div className={styles.ProductAdvertisementLabel}>
              <Labels percent={discount} position="relative" />
            </div>
          ) : null}
          {background ? (
            <div
              className={styles.ProductAdvertisementBackground}
              style={{ backgroundImage: `url('${magentoUrl}${background.replace('/media/', '')}')` }}
            />
          ) : null}
          {backgroundTop ? (
            <LazyLoad>
              <div className={styles.ProductAdvertisementBackgroundTop}>
                <Image src={`${magentoUrl}${backgroundTop.replace('/media/', '')}`} />
              </div>
            </LazyLoad>
          ) : null}
          {backgroundBottom ? (
            <div className={styles.ProductAdvertisementBackgroundBottom}>
              <Image src={`${magentoUrl}${backgroundBottom.replace('/media/', '')}`} />
            </div>
          ) : null}
          <div className={styles.ProductAdvertisementContent}>
            {!isAdvert && !hideHeader ? (
              <div className={styles.ProductAdvertisementHeader} style={{ color: headerColor }}>
                {header}
                {subtitle && <div className={styles.ProductAdvertisementSubtitle}>{subtitle}</div>}
                {showHeaderUnderline ? (
                  <div className={styles.ProductAdvertisementHeaderUnderline} style={{ background: headerColor }} />
                ) : null}
              </div>
            ) : null}
            {text && !isAdvert ? (
              <ul className={styles.ProductAdvertisementOptions}>
                {text.map((item, i) => {
                  return (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`optionsItem${i}`} className="before-icon-check">
                      {item}
                    </li>
                  );
                })}
              </ul>
            ) : null}
            {text && isAdvert ? <div className={styles.ProductAdvertisementSingleText}>{text}</div> : null}
            {textVerticalAlign === 'bottom' ? button : null}
          </div>
          {textVerticalAlign !== 'bottom' && button ? (
            <div className={styles.ProductAdvertisementButton}>{button}</div>
          ) : null}
        </LinkWrap>
      </div>
    );
  }
);

ProductAdvertisement.defaultProps = {
  textVerticalAlign: 'top',
  textAlign: 'left',
  textColor: '#000',
  headerColor: '#000'
};

ProductAdvertisement.propTypes = {
  /** Add border inside banner */
  border: PropTypes.bool,
  /** Url to background image of banner */
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Url to top edge background image */
  backgroundTop: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Url to bottom edge background image */
  backgroundBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Vertical position of text content inside banner */
  textVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
  /** Horizontal position of text content inside banner */
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),
  /** Color of text content inside banner */
  textColor: PropTypes.string,
  /** Show or hide cta button */
  showButton: PropTypes.bool,
  /** Set text that will be displayed inside cta button */
  buttonText: PropTypes.string,
  /** Header of text content */
  header: PropTypes.string,
  /** Set header color */
  headerColor: PropTypes.string,
  /** Double width of banner */
  doubleSized: PropTypes.bool
};
