import { EmptyProductList } from '@deity/falcon-ui-kit';
import React from 'react';
import { forceCheck } from 'react-lazyload';
import { State } from 'react-powerplug';
import { AddedToCartPopup } from '../Product/AddedToCartPopup/AddedToCartPopup';
import { AddedToCartErrorPopup } from '../Product/AddedToCartPopup/AddedToCartErrorPopup';
import { ProductAdvertisement } from '../Product/ProductAdvertisement/ProductAdvertisement';
import { ProductCard } from '../Product/ProductCard/ProductCard';
import { AddedToWishlistPopup } from '../Wishlist/AddedToWishlistPopup/AddedToWishlistPopup';
import styles from './ProductList.module.scss';

const DOUBLE_WIDTH_ID = '2';

const getProductAdvertisementCard = (cards, index, realIndex) => {
  const advertCards = [];

  cards.forEach(card => {
    if (card.position === index) {
      advertCards.push(
        <ProductAdvertisement
          key={`listItem${realIndex}`}
          currentIndex={realIndex}
          border={card.has_frame}
          background={card.image_background}
          textVerticalAlign={card.vertical_align_value}
          textAlign={card.horizontal_align_value}
          textColor={card.text_color ? `#${card.text_color}` : '#000'}
          header={card.title}
          subtitle={card.subtitle}
          hideHeader={card.hide_title}
          headerColor={card.title_color ? `#${card.title_color}` : '#000'}
          text={card.text}
          showButton={card.has_button}
          buttonText={card.button_text}
          buttonLink={card.link}
          gradientTopColor={`#${card.gradient_color_from}`}
          gradientBottomColor={`#${card.gradient_color_to}`}
          backgroundTop={card.image_top}
          backgroundBottom={card.image_bottom}
          doubleSized={card.width === DOUBLE_WIDTH_ID}
          showHeaderUnderline={card.title_underline}
          isLink={card.is_link === 'true'}
        />
      );

      realIndex += card.width === DOUBLE_WIDTH_ID ? 2 : 1;
    }
  });

  return {
    advertCards,
    advertCardsIndex: realIndex
  };
};

const getProductCard = (category, product, realIndex, setState, source) => {
  const showError = error => {
    setState({ showAddedToCartErrorPopup: true });
    setState({ addedToCartError: error });
  };
  const productCard = (
    <ProductCard
      product={product}
      category={category}
      area="productListing"
      currentIndex={realIndex}
      showAddedToWishlist={() => setState({ showAddedToWishlistPopup: true })}
      showAddedToCart={() => setState({ showAddedToCartPopup: true })}
      showAddedToCartError={error => showError(error)}
      source={source}
      productIndex={realIndex}
    />
  );

  realIndex += 1;

  return {
    productCard,
    productCardIndex: realIndex
  };
};

export class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      getPrevProps: () => this.props
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { products: prevProducts } = prevState.getPrevProps();
    const { products: nextProducts } = nextProps;

    if (prevProducts !== nextProducts) {
      setTimeout(forceCheck);
    }

    return null;
  }

  render() {
    const { category, products, advertisementCards, source } = this.props;

    if (!products || !products.length) {
      return <EmptyProductList />;
    }
    return (
      <State
        initial={{
          showAddedToCartPopup: false,
          showAddedToWishlistPopup: false,
          showAddedToCartErrorPopup: false,
          addedToCartError: ''
        }}
      >
        {({ state, setState }) => {
          let realIndex = 0;
          return (
            <React.Fragment>
              <div className={styles.ProductList}>
                {products.map((product, index) => {
                  const { advertCards, advertCardsIndex } = getProductAdvertisementCard(
                    advertisementCards,
                    index,
                    realIndex
                  );
                  const { productCard, productCardIndex } = getProductCard(
                    category,
                    product,
                    advertCardsIndex,
                    setState,
                    source
                  );

                  realIndex = productCardIndex;

                  return (
                    <React.Fragment key={`listItem${realIndex}`}>
                      {advertCards}
                      {productCard}
                    </React.Fragment>
                  );
                })}
              </div>
              {state.showAddedToWishlistPopup ? (
                <AddedToWishlistPopup
                  on={state.showAddedToWishlistPopup}
                  toggle={() => setState({ showAddedToWishlistPopup: false })}
                />
              ) : null}
              <AddedToCartPopup
                on={state.showAddedToCartPopup}
                toggle={() => setState({ showAddedToCartPopup: false })}
              />
              <AddedToCartErrorPopup
                on={state.showAddedToCartErrorPopup}
                toggle={() => setState({ showAddedToCartErrorPopup: false })}
                message={state.addedToCartError}
              />
            </React.Fragment>
          );
        }}
      </State>
    );
  }
}

ProductList.defaultProps = {
  advertisementCards: []
};
