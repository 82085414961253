import React from 'react';
import { Button } from '../../../../ui/Button/Button';
import { createRelativeStoreUrl, isExternalLink } from '../../../../helpers/Url/RelativeUrl';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';

export const CmsButton = withStoreConfiguration(
  ({ storeConfiguration, buttonText, absoluteLink, relativeLink, productLink, tracking, id, types, t }) => {
    const processedLink = [];

    if (absoluteLink) {
      processedLink.push(absoluteLink.trim());
    } else if (
      !absoluteLink &&
      productLink &&
      productLink.products &&
      productLink.products[0] &&
      productLink.products[0].storeUrls
    ) {
      const productUrl = productLink.products[0].storeUrls
        .filter(store => store.storeCode === t('domain.storeCode'))
        .map(store => {
          return store.url;
        });
      processedLink.push(productUrl[0]);
    } else if (!absoluteLink && !productLink && relativeLink && relativeLink.fields && relativeLink.fields.url) {
      processedLink.push(relativeLink.fields.url);
    } else {
      return null;
    }

    const isExternal = isExternalLink(processedLink[0], storeConfiguration);
    if (processedLink && processedLink[0] && !isExternal) {
      processedLink[0] = createRelativeStoreUrl(processedLink[0]);
    }

    if (!buttonText && types === 'invisible') {
      return (
        <React.Fragment>
          {processedLink ? (
            <Button
              id={id}
              types={types}
              asStandardLink={isExternal}
              asLink={!isExternal}
              path={processedLink[0]}
              tracking={tracking}
              size="small"
            />
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {buttonText && processedLink ? (
          <Button
            id={id}
            types={types}
            asStandardLink={isExternal}
            asLink={!isExternal}
            path={processedLink[0]}
            tracking={tracking}
            size="small"
          >
            {buttonText}
          </Button>
        ) : null}
      </React.Fragment>
    );
  }
);
