import storeConfig from 'src/storesConfig';
// Make Url relative
export const createRelativeStoreUrl = url => {
  const storeViewsConfig = storeConfig();
  if (typeof url === 'string') {
    // replace all chars until first single slash (double slashes and all other chars)
    url = url.replace(/^(?:\/\/|[^/]+)*/, '');
    storeViewsConfig.storeViewsMap.forEach(item => {
      // replace store suffix
      if (url === `/${item.suffix}` || url.indexOf(`/${item.suffix}/`) === 0) {
        url = url.replace(`/${item.suffix}`, '');
      }
    });
  }
  return url;
};

export const createRelativeUrl = url => {
  if (typeof url === 'string') {
    return url.replace(/^(?:\/\/|[^/]+)*/, '');
  }
  return url;
};

// check if link is external
export const isExternalLink = (url, storeConfiguration) => {
  if (url && url.includes('http')) {
    return !(
      url.includes(storeConfiguration.web.secure.base_url) ||
      url.includes(storeConfiguration.asam_contentful.general.base_link_domain)
    );
  }
  return false;
};
