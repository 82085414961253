import React from 'react';
import { Link } from 'react-router-dom';

export const LinkWrap = ({ wrap, link, standardHtmlLink, children }) => {
  if (wrap) {
    if (standardHtmlLink) {
      return <a href={link}>{children}</a>;
    }
    return <Link to={link}>{children}</Link>;
  }

  return children;
};

LinkWrap.defaultProps = {
  standardHtmlLink: false
};
